import React from 'react'
import bringIntoView from './bringIntoView'
import footerImage from '../images/OP_Icon.png'
import VideoModal from './videoModal.js'

const SignIn = ({ introVideo }) => {
    const { SIGNIN_URL } = process.env
    const signinUrl = !SIGNIN_URL ? 'https://ipsa.oneprotection.tech' : SIGNIN_URL

    return (
        <div id="sign-up">
            <div className="sign-up-left">
                <span>
                    <img src={footerImage} /> OneProtection &reg;
                </span>
            </div>
            <div className="sign-up-right">
                {introVideo && (
                    <VideoModal label={introVideo.label} videoUrl={introVideo.file.file.url} />
                )}
                <button onClick={bringIntoView.bind(this, '#quotes')}>Plan Pricing</button>
                <button onClick={bringIntoView.bind(this, '#journey')}>Contact Us</button>
                <a href={signinUrl}>Sign In</a>
            </div>
        </div>
    )
}

export default SignIn
