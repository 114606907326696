import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

const VideoModal = props => {
    const { label, videoUrl } = props

    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => {
        if (!window) return
        setIsOpen(true)
    }

    const closeModal = () => {
        setIsOpen(false)
    }

    const renderModal = () => {
        if (!isOpen) return ''

        return ReactDOM.createPortal(
            <>
                <div id="modal-cover"></div>
                <div id="modal-video" className="modal">
                    <div className="embed-container">
                        <video
                            controls
                            name="media"
                            style={{
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '100%',
                                height: '100%',
                            }}
                            autoPlay
                        >
                            <source src={videoUrl} allowFullScreen></source>
                        </video>
                    </div>
                    <button onMouseDown={closeModal}>CLOSE</button>
                </div>
            </>,
            document.body
        )
    }

    return (
        <>
            <button onMouseDown={openModal}>{label}</button>
            {renderModal()}
        </>
    )
}

export default VideoModal

VideoModal.propTypes = {
    label: PropTypes.string.isRequired,
    videoUrl: PropTypes.string.isRequired,
}
