import React from 'react'

import Layout from '../components/layout'
import SignIn from '../components/signin'
import SignupPlans from '../components/signupPlans'
import SEO from '../components/seo'
import { leftArrow } from '../components/carouselSupport/arrows.js'

import '../sass/core.scss'

const SignupPage = () => {
    return (
        <Layout mainClass="signup-page">
            <SEO title="Sign Up" />
            <SignIn />
            <img
                id="back-nav"
                onClick={() => (window.location.href = '/')}
                src={leftArrow}
                alt="back navigation"
                width="28"
                style={{ paddingLeft: '10%' }}
            />
            <SignupPlans />
        </Layout>
    )
}

export default SignupPage
