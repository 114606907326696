import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'


const SignupPlans = () => {
    const data = useStaticQuery(graphql`
        query PlansQuery {
            contentfulMarketingPage {
                signupPlans {
                    name
                    price
                    features
                    discountPrice
                }
            }
        }
    `)

    const { signupPlans } = data.contentfulMarketingPage
    return (
        <div className="col-section col-no-margin planContainer">
            {signupPlans.map((plan, i) => {
                return (
                    <div key={plan.name} className="plan">
                        <div className={'headerContainer headerContainer-' + i}>
                            <div>{plan.name}</div>
                            {plan.discountPrice ? (
                                <div>
                                    <span style={{ textDecoration: 'line-through' }}>
                                        ${plan.price}
                                    </span>
                                    <span> ${plan.discountPrice}</span>
                                </div>
                            ) : (
                                <div>${plan.price}</div>
                            )}
                        </div>
                        <div className="infoContainer">
                            <ul className="features">
                                {plan.features.map(_ => (
                                    <li key={_}>{_}</li>
                                ))}
                            </ul>
                            <a
                                className="selectPlan btn"
                                href="https://ipsa.oneprotection.tech/?signup=signup&affid=ipsa_web"
                            >
                                Sign Up
                            </a>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default SignupPlans
