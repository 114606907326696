const bringIntoView = selector => {
    $('html, body').animate(
        {
            scrollTop: $(selector).offset().top,
        },
        600
    )
}

export default bringIntoView
